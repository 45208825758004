<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Users" subtitle="Browse user accounts across all conventions." />

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no accounts found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Ref." />
				<app-table-column text="Name" />
				<app-table-column text="Account created" />
				<app-table-column text="Last active" />
				<app-table-column text="Badges" align="center" />
				<app-table-column text="Teans" align="center" />

			</app-table-head>

			<app-table-body>

				<app-table-row align="top" :selected="isSelected(item.id)" v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.reference" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="item.created | localDate('Do MMMM YYYY')" alt="Unknown date" />
					<app-table-cell-text :text="item.active | localDate('Do MMMM YYYY')" alt="Never logged in" />
					<app-table-cell-text :text="item.count.badges" align="center" />
					<app-table-cell-text :text="item.count.teams" align="center" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			endpoint: 'users/accounts',
			live: 'users/accounts',
			edit: 'Users.Accounts.Edit',
			layout: '100px auto 200px 200px 120px 120px'
		}

	}

}

</script>

<style scoped>

</style>